import {Scene, Texture, StandardMaterial, Color3 } from 'babylonjs';
import { BallAssets } from '../types/BallAssets';

// basic soccerball
export const createBallMaterial = (scene:Scene):BallAssets => {
    const ballTexture = new Texture("../assets/3d-models/Images/soccer-ball.jpg?nocache=1740671827327", scene);
    const ballMaterial = new StandardMaterial("metalMaterial", scene);
    ballTexture.coordinatesMode = 1;
    ballMaterial.alpha = 0;
    ballMaterial.diffuseTexture = ballTexture;
    ballMaterial.specularColor = new Color3(1,1,1);
    ballMaterial.freeze();

    return {
        ballMaterial:ballMaterial,
        ballTexture: ballTexture
    };
}