import {Color3, StandardMaterial, Vector3, PhysicsImpostor, TransformNode, Scene, Texture, Engine} from 'babylonjs';
import Obstacle from '../types/Obstacle';
import {Game} from '../index'

export interface BackboardFile {
    src:string, 
    fromDate?:string, 
    toDate?:string
}
export const goalies:string[] = [
    'Pinball-goalie.png'
];
export const highlightColor:Color3 = new Color3(0.968627, 0.650980, 0.000000);
export const musicFiles:string[] = [
    'bg-armhem-1',
	'bg-forza-vitesse',
	'bg-vitesse-arnhem'
];
export const backboardFiles:BackboardFile[] = [
    {
        src: "./assets/3d-models/Images/backboard-01.jpg?nocache=1740671827327"
    },
    {
        src: "./assets/3d-models/Images/backboard-02.jpg?nocache=1740671827327",
        fromDate:"2022-12-16",
        toDate: "2022-12-25"
    },
    {
        src: "./assets/3d-models/Images/backboard-03.jpg?nocache=1740671827327"
    }
];
export const buttons:Record<string, string> = {
    "soundOff": "./assets/3d-models/Images/Pinball-Button-geluid-uit.png",
    "soundOn": "./assets/3d-models/Images/Pinball-Button-geluid-aan.png"
}
export interface RedirectButton {
    multiMaterial:boolean,
    materials?: {
        shine: (scene:Scene) => {texture:Texture, material:StandardMaterial},
        overlay: (scene:Scene) => {texture:Texture, material:StandardMaterial}
    }
}
export const redirectButton:RedirectButton = {
    multiMaterial: true,
    materials: {
        shine: (scene:Scene)=>{            
            let texture = new Texture(`./assets/3d-models/Images/button-shine.png?nocache=1740671827327`, scene);
            texture.hasAlpha = true;
            texture.uOffset = 1;
            texture.vOffset = 1;
            let material = new StandardMaterial("ButtonShineMaterial", scene);
            material.diffuseTexture = texture;
            material.useAlphaFromDiffuseTexture = true;
            material.alpha = 0;
            return {texture, material}
        },
        overlay: (scene:Scene) =>{
           	let texture = new Texture("./assets/3d-models/Images/button-enter-txt.png?nocache=1740671827327", scene);
            texture.hasAlpha = true;
            let material = new StandardMaterial('ButtonOverlayMaterial', scene);
		    material.alphaMode = Engine.ALWAYS;
		    material.diffuseTexture = texture;
		    material.specularColor = new Color3(0,0,0)
		    material.useAlphaFromDiffuseTexture = true;
            return {texture, material}
        }
    }
}



export const obstacles:Obstacle[] = [
    {
        name: "kado",
        fileName: "Kado.obj",
        position: new Vector3(-13, 0, -11),
        rotationY: 1.2 * Math.PI,
        scale: 10.8,
        score: 20,
        processObject: (meshes,  scene, obstacle, createShadowPlane) => {
            try {
                let object = meshes[0];
    
                object.name = obstacle.name;
                if(obstacle.scale) {
                    object.scaling.x = object.scaling.y = object.scaling.z = obstacle.scale;
                }
                
                object.position.copyFrom(obstacle.position);
                object.position.y = 300;
                
                object.rotation.y = obstacle.rotationY;
                
                object.physicsImpostor = new PhysicsImpostor(object, PhysicsImpostor.BoxImpostor, {mass:0, restitution: Game.OBSTACLE_RESTITUTION, friction:Game.OBSTACLE_FRICTION}, scene);
    
                let shadowplane = createShadowPlane(`${obstacle.name}_shadow`, 10, 10);
                shadowplane.position.copyFrom(obstacle.position);
                shadowplane.position.y = 0.09999;
                shadowplane.position.z += -0.5
                shadowplane.position.x += -0.5
                shadowplane.rotation.y = obstacle.rotationY;
                shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
                shadowplane.visibility = 0;
    
                return {
                    name:obstacle.name,
                    lastTrigger:0,
                    cooldown:1000,
                    isGoal:false,
                    points: obstacle.score,
                    renderedObject: object,
                    collisionObject: object,
                    fakeShadows: [shadowplane],
                    position: obstacle.position,
                    zone: obstacle.zone
                }
            } catch (e) {
                console.error(`unable to process ${obstacle.name}`, e);
            }
            return;
        },
        zone: "topRight"
    },
    {
        name: "basket",
        fileName: "Winkelmand.obj",
        rotationY: 1.2 * Math.PI,
        position: new Vector3(15, 0, 28),
        zone: 'bottomLeft',
        score:10,
        scale: 1.2 * 2.5,
        processObject: (meshes,  scene, obstacle, createShadowPlane) => {
            try {
            	let object = meshes[0];
            
                for(let mesh of meshes) {
                    if(mesh != object) {
                        mesh.setParent(object);
                    }
                }

                let rotation = obstacle.rotationY;
                object.name = obstacle.name;
                object.scaling.x = object.scaling.y = object.scaling.z = obstacle.scale;
                object.material.backFaceCulling = false;
                object.position.copyFrom(obstacle.position);
                object.position.y = 300;
                object.rotation.y = rotation
                object.physicsImpostor = new PhysicsImpostor(object, PhysicsImpostor.BoxImpostor, {mass:0, restitution: Game.OBSTACLE_RESTITUTION, friction:Game.OBSTACLE_FRICTION}, scene);
                // object.receiveShadows = true;
                
                let shadowplane3 = createShadowPlane('sled_shadow', 10, 18);
                shadowplane3.position.x = 16
                shadowplane3.position.z = 27
                shadowplane3.position.y = 0.09999;
                shadowplane3.rotation.z = Math.PI * 1.3;
                shadowplane3.scaling = new Vector3(0.1, 0.1, 0.1);
                shadowplane3.visibility = 0;

                return {
                    name:obstacle.name,
                    lastTrigger:0,
                    cooldown:1000,
                    isGoal:false,
                    points: obstacle.score,
                    renderedObject: object,
                    collisionObject: object,
                    fakeShadows: [shadowplane3],
                    position: obstacle.position,
                    zone: obstacle.zone
                }
            } catch (e) {
                console.error(`unable to process ${obstacle.name}`, e);
            }
            return;
        }
    },
    {
        name:"shoes",
        position: new Vector3(-15, 1.9, 28),
        rotationY: 0.6*Math.PI,
        zone:'bottomRight',
        fileName:"Schoen.obj",
        score:10,
        processObject: (meshes,  scene, obstacle, createShadowPlane) => {
            try {
                let shoes = new TransformNode('shoes');
                shoes.name = 'shoes';
                meshes[0].setParent(shoes);
                meshes[1].setParent(shoes);
                meshes[0].material.backFaceCulling = false;
                meshes[1].material.backFaceCulling = false;
                shoes.scaling.x = shoes.scaling.y = shoes.scaling.z = 1.2 * 2.5;
                shoes.scaling.x *= -1;
                
                shoes.position.copyFrom(obstacle.position);
                shoes.position.y = 300;
                shoes.rotation.y = 0;
    
                let shoesDummy = Game.createObstacle(scene, obstacle.position.clone(), 8, 10, 9);
                shoesDummy.position.x += 0.2;
                shoesDummy.position.z += 0.7; 
                shoesDummy.rotation.y = 0.6*Math.PI;
    
                
                let shadowplane = createShadowPlane('shoes_shadow', 10, 24.5, true);
                shadowplane.position.copyFrom(obstacle.position);
                shadowplane.position.x = -17;
                shadowplane.position.z = 28;
                shadowplane.rotation.y = 1 * Math.PI;
                shadowplane.position.y = 0.09999;
                shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
                shadowplane.visibility = 0;
    
                let shadowplane2 = createShadowPlane('shoes2_shadow', 10, 22.5, true);
                shadowplane2.position.copyFrom(obstacle.position);
                shadowplane2.position.x = -14.25
                shadowplane2.position.z = 27;
                shadowplane2.rotation.y = 0.35 * Math.PI;
                shadowplane2.position.y = 0.09999;
                shadowplane2.scaling = new Vector3(0.1, 0.1, 0.1);
                shadowplane2.visibility = 0;

                return {
                    name:obstacle.name,
                    lastTrigger:0,
                    cooldown:1000,
                    isGoal:false,
                    points: obstacle.score,
                    renderedObject: shoes,
                    collisionObject: shoesDummy,
                    fakeShadows: [shadowplane, shadowplane2],
                    position: obstacle.position,
                    zone: obstacle.zone
                }
            } catch(e) {
                console.error(`unable to process ${obstacle.name}`, e);
            }
            return;
        }
    },
    {
        name: "trophy",
        fileName: "Beker.obj",
        position: new Vector3(15, 0, -11),
        rotationY: 1.8 * Math.PI,
        scale: 1.2 * 1.95,
        score:20,
        zone:"topLeft",
        processObject: (meshes,  scene, obstacle, createShadowPlane) => {
            try {
                let object = meshes[0];
            
                for(let mesh of meshes) {
                    if(mesh != object) {
                        mesh.setParent(object);
                    }
                }

                let rotation = obstacle.rotationY;
                object.name = obstacle.name;
                object.scaling.x = object.scaling.y = object.scaling.z = obstacle.scale;
                object.material.backFaceCulling = false;
                object.position.copyFrom(obstacle.position);
                    object.position.y = 300;
                    object.rotation.y = rotation
                    object.physicsImpostor = new PhysicsImpostor(object, PhysicsImpostor.CylinderImpostor, {mass:0, restitution: Game.OBSTACLE_RESTITUTION, friction:Game.OBSTACLE_FRICTION}, scene);
                    object.receiveShadows = true;

                    let shadowplane = createShadowPlane(`${obstacle.name}_shadow`, 20.25, 24.75, true);
                    shadowplane.position.copyFrom(obstacle.position);
                    shadowplane.position.y = 0.09999;
                    shadowplane.rotation.y = obstacle.rotationY;
                    shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
                    shadowplane.visibility = 0;

                return {
                    name:obstacle.name,
                    lastTrigger:0,
                    cooldown:1000,
                    isGoal:false,
                    points: obstacle.score,
                    renderedObject: object,
                    collisionObject: object,
                    fakeShadows: [shadowplane],
                    position: obstacle.position,
                    zone: obstacle.zone
                }
            } catch (e) {
                console.error(`unable to process ${obstacle.name}`, e);
            }
            return;
        }
    }
];